<template>
    <div
        class="dropdown"
        :class="{
            'dropdown--opened': is_opened && !disabled,
            'dropdown--disabled': disabled,
        }"
        :style="css_vars"
    >

        <div class="dropdown__trigger" @click="toggle">
            <div class="dropdown__header">
                <slot name="header" />
            </div>
        </div>

        <div
            class="dropdown__body"
            ref="scroll"
        >
            <slot name="body" />
        </div>

    </div>
</template>

<script>
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

export default {
    props: {
        disabled:           { type: Boolean, default: false   },

        headerPaddingRight: { type: String,  default: '16px'  },
        triggerIconSize:    { type: String,  default: '24px'  },
        bodyMaxHeight:      { type: String,  default: '150px' },
        bodyTop:            { type: String,  default: '100%' },
        bodyRight:          { type: String,  default: '0px' },
        bodyLeft:           { type: String,  default: '0px' },
    },

    data() {
        return {
            is_opened: false,
        }
    },

    computed: {
        css_vars() {
            return {
                '--header-padding-right':   this.headerPaddingRight,
                '--trigger-icon-size':      this.triggerIconSize,
                '--body-max-height':        this.bodyMaxHeight,
                '--body-top':               this.bodyTop,
                '--body-right':             this.bodyRight,
                '--body-left':              this.bodyLeft,
            }
        }
    },

    methods: {
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.close()
            }
        },

        open() {
            this.is_opened = true
            document.addEventListener('click', this.handleClickOutside)

            this.$emit('open')
        },

        close() {
            this.is_opened = false
            document.removeEventListener('click', this.handleClickOutside)

            this.$emit('close')
        },

        toggle() {
            if (!this.disabled) {
                if (this.is_opened) {
                    this.close()
                } else {
                    this.open()
                }
            }
        },

        initSimpleBar() {
            this.simple_bar = new SimpleBar(this.$refs.scroll, {
                autoHide: false
            })
        },
    },

    mounted() {
        this.initSimpleBar()
    },
}
</script>

<style lang="scss">

.dropdown {
    $root: &;

    position: relative;
    display: flex;

    &--opened {
        z-index: $z-index-dropdown;
    }

    &--disabled {
        opacity: 0.4;
    }

    &__trigger {
        position: relative;
        flex: 1 1 auto;
        max-width: 100%;

        display: flex;

        cursor: pointer;
        user-select: none;

        #{$root}--disabled & {
            cursor: default;
        }

        padding-right: calc( var(--trigger-icon-size) + var(--header-padding-right) );

        @include icon('chevron-down', 'after');

        #{$root}--opened & {
            @include icon('chevron-up', 'after');
        }

        &::after {
            position: absolute;
            top: 50%;
            margin-top: calc( var(--trigger-icon-size) / -2 );
            right: var(--header-padding-right);
            font-size: var(--trigger-icon-size);

            z-index: 1;
        }
    }

    &__header {
        flex: 1 1 auto;

        @include text-overflow;
    }

    &__body {
        @include webkit-simplebar-vertical(4px);

        display: none;
        position: absolute;
        top: var(--body-top);
        left: var(--body-left);
        right: var(--body-right);

        max-height: var(--body-max-height);

        background-color: inherit;

        box-shadow: 0 4px 16px 0 rgba($black, 0.1);

        #{$root}--opened & {
            display: block;
        }
    }
}
</style>